import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';
import filterBuilder from './filterBuilder';

const GET_CONTACT_OPTIONS = gql`
query GetContacts($limit: Int!, $page: Int!, $filters: ContactFiltersInput!) {
  Contacts(limit: $limit, page: $page, filters: $filters) {
    ... on BaseError {
      message
    }
    ... on QueryContactsSuccess {
      data {
        data {
          id
          name
        }
        elementsPerPage
        total
      }
    }
  }
}
`;

const elementsPerPage = 20;

const getContactOptions = async ( { ...rest } ) => {
  const params = filterBuilder( rest, ['name'] );

  const { data } = await client.query( {
    query: GET_CONTACT_OPTIONS,
    variables: {
      page: 1, // pageCurrent,
      limit: elementsPerPage,
      filters: {
        ...params,
        isActive: true,
      },
    },
    fetchPolicy: 'no-cache',
  } );

  const contacts = data.Contacts.data.data || [];
  const elementsTotal = data.Contacts.data.total || 0;

  if ( data.Contacts.message ) {
    return {
      ok: false,
      message: data.Contacts.message,
    };
  }

  return {
    ok: true,
    status: 200,
    data: { data: contacts, elementsTotal, elementsPerPage },
  };
};

export default getContactOptions;
