import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';
import filterBuilder, { sortByBuilder } from './filterBuilder';

const GET_PIPELINES = gql`



query GetPipelines($filters: PipelineFiltersInput!, $orderBy: [PipelineOrderByInput!]!, $limit: Int!, $page: Int!) {
  Pipelines(limit: $limit, page: $page, filters: $filters, orderBy: $orderBy) {
    ... on BaseError {
      message
    }
    ... on QueryPipelinesSuccess {
      data {
        total
        elementsPerPage
        data {
          id
          isActive
          name
          stages
          updatedAt
          description
          createdAt
        }
      }
    }
  }
}
`;

const getPipelines = async ( { page, elementsPerPage, ...rest } ) => {
  const filters = filterBuilder( rest, ['name'] );
  const orderBy = sortByBuilder( rest );

  const { data } = await client.query( {
    query: GET_PIPELINES,
    variables: {
      page: page || 1, // pageCurrent,
      limit: elementsPerPage || 10,
      filters,
      orderBy,
    },
    fetchPolicy: 'no-cache',
  } );

  const pipelines = data.Pipelines.data.data || [];
  const elementsTotal = data.Pipelines.data.total || 0;

  if ( data.Pipelines.message ) {
    return {
      ok: false,
      message: data.Pipelines.message,
    };
  }

  return {
    ok: true,
    status: 200,
    data: { data: pipelines, elementsTotal, elementsPerPage },
  };
};

export default getPipelines;
