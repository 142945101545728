import React, {
  useState, useMemo, useCallback, useEffect,
} from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import FormBusinessEditView from 'views/CRM/Business/Edit/form';
import { useFetch, useSubmit } from 'hooks';
import {
  getBusinessOpportunity, updateBusinessOpportunity,
  createBusinessOpportunity, getPipelines, getContactOptions,
} from 'api/CRM/Contact';
import moment from 'moment';

const FormBusinessEdit = ( {
  businnesId, extraData, open, onClose, onReloadData, createdBy, toggleErrorAlert, toggleLoading,
  toggleInfoAlert,
} ) => {
  /* PIPELINES */
  const [pipelines, pipelinesLoading] = useFetch( {
    promise: ( ) => getPipelines( { elementsPerPage: 9999, page: 1 } ),
    format: ( d ) => d?.data || [],
    initialState: [],
    conditional: !!open,
    reInit: true,
  } );

  const [pipelineId, setPipelineId] = useState( null );

  const pipelineSelected = useMemo( () => {
    if ( pipelineId ) {
      return pipelines.find( ( p ) => p.id === pipelineId );
    }
    return null;
  }, [pipelines, pipelineId] );

  // CONTACTS
  const fetchContacts = useCallback( ( query ) => getContactOptions( {
    name: query,
  } ), [] );

  // BUSINNES
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => getBusinessOpportunity( businnesId ),
    toggleErrorAlert,
    conditional: !!businnesId,
    deps: [businnesId],
  } );

  useEffect( () => {
    const dataComplete = {
      ...data,
      ...( extraData || {} ),
    };
    if ( dataComplete.pipelineId ) {
      setPipelineId( dataComplete.pipelineId );
    }
  }, [data, extraData] );

  const onSubmit = useSubmit( {
    promise: ( dataToSend ) => {
      const dataFormatted = { ...dataToSend };
      dataFormatted.closeDate = moment.utc( dataToSend.closeDate ).format( 'YYYY-MM-DD' );

      if ( businnesId ) {
        return updateBusinessOpportunity( { ...dataFormatted, id: businnesId } );
      }
      return createBusinessOpportunity( { ...dataFormatted, pipelineId, createdBy } );
    },
    toggleLoading,
    toggleInfoAlert,
    toggleErrorAlert,
    callback: ( ) => {
      onClose( );
      if ( onReloadData ) onReloadData();
    },
    deps: [businnesId, pipelineId, createdBy],
  } );

  return (
    <FormBusinessEditView
      businnesId={businnesId}
      onClose={onClose}
      data={{
        ...data,
        ...( extraData || {} ),
      }}
      loading={dataLoading}
      onSubmit={onSubmit}
      //
      pipelines={pipelines}
      pipelinesLoading={pipelinesLoading}
      pipelineSelected={pipelineSelected}
      pipelineId={pipelineId}
      setPipelineId={setPipelineId}
      //
      fetchContacts={fetchContacts}
    />
  );
};

const mapStateToProps = ( { user } ) => ( { createdBy: user?.user?.user?.email || '' } );

const mapDispatchToProps = {
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( FormBusinessEdit );
