import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';

const DELETE_CONTACT_NOTE = gql`
mutation DeleteContactNote($id: String!) {
  deleteNote(id: $id) {
    ... on BaseError {
      message
    }
    ... on MutationDeleteNoteSuccess {
      data {
        success
      }
    }
  }
}
`;

const deleteContactNote = async ( id ) => {
  const { data } = await client.query( {
    query: DELETE_CONTACT_NOTE,
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.deleteNote.message ) {
    return {
      ok: false,
      message: data.deleteNote.message,
    };
  }
  const deleteSuccessful = data.deleteNote.data.success || false;

  return {
    ok: deleteSuccessful,
    status: 200,
  };
};

export default deleteContactNote;
