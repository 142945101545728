import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';

const CREATE_CONTACT_NOTE = gql`
mutation CreateContactNote($contactId: String!, $status: String!, $text: String!, $userId: String!, $userName: String!) {
  createNote(
    contactId: $contactId
    status: $status
    text: $text
    userId: $userId
    userName: $userName
  ) {
    ... on BaseError {
      message
    }
    ... on MutationCreateNoteSuccess {
      data {
        success
      }
    }
  }
}
`;

const createContactNote = async ( variables ) => {
  const { data } = await client.query( {
    query: CREATE_CONTACT_NOTE,
    variables,
    fetchPolicy: 'no-cache',
  } );

  if ( data.createNote.message ) {
    return {
      ok: false,
      message: data.createNote.message,
    };
  }

  const createSuccessful = data.createNote.data.success || false;

  return {
    ok: createSuccessful,
    status: 200,
    data: { ...data.createNote.data },
  };
};

export default createContactNote;
