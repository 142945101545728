import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';

const DELETE_EMAIL = gql`
mutation DeleteEmail($id: String!) {
  deleteEmail(id: $id) {
    ... on BaseError {
      message
    }
    ... on MutationDeleteEmailSuccess {
      data {
        success
      }
    }
  }
}
`;

const deleteEmail = async ( id ) => {
  const { data } = await client.query( {
    query: DELETE_EMAIL,
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.deleteEmail.message ) {
    return {
      ok: false,
      message: data.deleteEmail.message,
    };
  }
  const deleteSuccessful = data.deleteEmail.data.success || false;

  return {
    ok: deleteSuccessful,
    status: 200,
  };
};

export default deleteEmail;
