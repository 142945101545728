import React, {
  createContext, useState, useCallback,
  useMemo,
  useEffect,
} from 'react';
import { useFetch } from 'hooks';
import { getPipelines } from 'api/CRM/Contact';

const tabsList = [
  { name: 'crm.business.allContacts' },
  { name: 'crm.business.myBusiness' },
];
const viewList = [
  { icon: 'fa fa-list', id: 1 },
  { icon: 'fa fa-th-large', id: 2 },
];

const defaultTab = 'crm.business.allContacts';
const defaultView = 1;

export const BusinnesCRMContext = createContext( {
  tabsList,
  selectedTab: defaultTab,
  setSelectedTab: () => {},
  viewList,
  selectedView: defaultView,
  setSelectedView: () => {},
  //
  filters: { page: 1, elementsPerPage: 10 },
  setFilter: () => {},
  //
  reloadFlag: new Date().valueOf(),
  onReload: () => {},
  //
  businnessToEdit: null,
  businnessToEditExtraData: {},
  businessEditModalOpened: false,
  toggleBusinessEditModal: () => {},
  onEditBusiness: () => {},
  //
  pipelines: [],
  pipelinesLoading: false,
  pipelineSelected: null,
} );

const BusinnesCRMContextProvider = ( {
  children, currentCreatedBy,
} ) => {
  const [selectedTab, setSelectedTab] = useState( defaultTab );
  const [selectedView, setSelectedView] = useState( defaultView );

  const [filters, setFilterChanges] = useState( {
    page: 1,
    elementsPerPage: 10,
  } );

  const setFilter = useCallback( ( newFilters ) => {
    setFilterChanges( ( oldFilters ) => Object
      .entries( { ...oldFilters, ...newFilters } )
      .reduce( ( acc, [key, value] ) => {
        if ( !( typeof value === 'undefined' || value === null || value === '' ) ) {
          acc[key] = value;
        }
        return acc;
      }, {} ) );
  }, [] );

  const [
    reloadFlag,
    setReloadFlag,
  ] = useState( new Date().valueOf() );

  const onReload = useCallback( () => {
    setReloadFlag( new Date().valueOf() );
  }, [] );

  /// ////////////////

  const [businnessToEdit, setBusinessToEdit] = useState( null );
  const [businnessToEditExtraData, setBusinessToEditExtraData] = useState( {} );
  const [businessEditModalOpened, setBusinessEditModalOpened] = useState( false );

  const toggleBusinessEditModal = useCallback( ( ) => {
    setBusinessEditModalOpened( ( v ) => !v );
  }, [] );

  const onEditBusiness = useCallback( ( id, extraData ) => {
    setBusinessToEditExtraData( extraData || {} );
    setBusinessToEdit( id );
    toggleBusinessEditModal();
  }, [toggleBusinessEditModal] );

  /* PIPELINES */
  const [pipelines, pipelinesLoading] = useFetch( {
    promise: ( ) => getPipelines( { elementsPerPage: 9999, page: 1 } ),
    format: ( d ) => d?.data || [],
    initialState: [],
    reInit: true,
  } );

  const pipelineSelected = useMemo( () => {
    if ( filters.pipelineId ) {
      return pipelines.find( ( p ) => p.id === filters.pipelineId );
    }
    return null;
  }, [pipelines, filters] );

  // CREATED BY FILTER
  useEffect( () => {
    if ( selectedTab === tabsList[1].name ) {
      setFilter( { createdBy: currentCreatedBy } );
    } else {
      setFilter( { createdBy: undefined } );
    }
  }, [currentCreatedBy, setFilter, selectedTab] );

  return (
    <BusinnesCRMContext.Provider
      value={{
        tabsList,
        selectedTab,
        setSelectedTab,
        viewList,
        selectedView,
        setSelectedView,
        //
        filters,
        setFilter,
        //
        reloadFlag,
        onReload,
        //
        businnessToEdit,
        businnessToEditExtraData,
        businessEditModalOpened,
        toggleBusinessEditModal,
        onEditBusiness,
        //
        pipelines,
        pipelinesLoading,
        pipelineSelected,
      }}
    >
      {children}
    </BusinnesCRMContext.Provider>
  );
};
export default BusinnesCRMContextProvider;
