import React from 'react';
import moment from 'moment';
import {
  Intl,
  Form, FormActionsBtns,
  TextField, Select,
  Datepicker,
  AsyncSelect,
} from 'components';
import { Col, Row } from 'reactstrap';
import { required } from 'config/InputErrors';
import {
  contactBusinnesStatuses,
} from 'config/Constants/ContactBusinnesStages';
import classNames from 'classnames';

const FormBusinessEditView = ( {
  businnesId, onClose, data, loading, onSubmit,
  //
  pipelines,
  pipelinesLoading,
  setPipelineId,
  pipelineSelected,
  //
  fetchContacts,
} ) => (
  <div className={classNames( 'p-4', {
    'app-component-loading': loading,
  } )}
  >
    <h2><Intl id={businnesId ? 'crm.businnes' : 'crm.businness.createBusinness'} /></h2>
    <hr className="mt-0" />
    <Form
      initialValues={{
        ...data,
        closeDate: data.closeDate ? moment( data.closeDate ).format( 'YYYY-MM-DD' ) : null,
      }}
      showErrorsInFooter
      onSubmit={onSubmit}
      body={( ) => (
        <>
          <Row className="mb-3">
            {businnesId ? null : (
              <Col md={6}>
                <Select
                  label="crm.businnesStatuses.pipeline"
                  field="pipelineId"
                  options={pipelines}
                  isLoading={pipelinesLoading}
                  // validate={required}
                 // value={pipelineId}
                  onChange={setPipelineId}
                  defaultOptions
                />
              </Col>
            )}
            <Col md={6}>
              <Select
                label="crm.businnesStatuses.stage"
                field="businessStage"
                options={pipelineSelected
                  ? pipelineSelected.stages
                    .map( ( stage ) => ( { id: stage, name: stage } ) ) : []}
                validate={required}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={9}>
              <TextField
                label="name"
                field="businessName"
                placeholder="name"
                validate={required}
              />
            </Col>
          </Row>
          {businnesId ? null : (
            <Row className="mb-3">
              <Col md={7}>
                <AsyncSelect
                  field="contactId"
                  label="crm.businnesStatuses.contact"
                  defaultOptions
                  loadOptions={fetchContacts}
                  validate={required}
                />
              </Col>
            </Row>
          )}

          <Row className="mb-3">
            <Col md={5}>
              <Select
                label="state"
                field="state"
                placeholder="all"
                translateOptions
                options={contactBusinnesStatuses}
                validate={required}
              />
            </Col>
            <Col md={7}>
              <Datepicker
                field="closeDate"
                label="crm.businnesStatuses.closeDate"
                dateFormat="YYYY-MM-DD"
                timeFormat={false}
                validate={required}
              />
            </Col>
          </Row>
        </>
      )}
      footer={<FormActionsBtns onClickCancel={onClose} />}
    />
  </div>
);

export default FormBusinessEditView;
