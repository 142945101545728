import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';

const UPDATE_CONTACT_NOTE = gql`
mutation updateContactNote($text: String!, $id: String!) {
  updateNote(id: $id, text: $text) {
    ... on BaseError {
      message
    }
    ... on MutationUpdateNoteSuccess {
      data {
        success
      }
    }
  }
}
`;

const updateContactNote = async ( variables ) => {
  const { data } = await client.query( {
    query: UPDATE_CONTACT_NOTE,
    variables,
    fetchPolicy: 'no-cache',
  } );

  if ( data.updateNote.message ) {
    return {
      ok: false,
      message: data.updateNote.message,
    };
  }

  const updateSuccessful = data.updateNote.data.success || false;

  return {
    ok: updateSuccessful,
    status: 200,
  };
};

export default updateContactNote;
